/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DownloadOrdersDirectlyCheckBarcodesWarningQueryVariables = {
    barcodes: Array<string>;
};
export type DownloadOrdersDirectlyCheckBarcodesWarningQueryResponse = {
    readonly checkIfGtinsExist: {
        readonly notFound: ReadonlyArray<string> | null;
        readonly found: ReadonlyArray<string> | null;
    } | null;
};
export type DownloadOrdersDirectlyCheckBarcodesWarningQuery = {
    readonly response: DownloadOrdersDirectlyCheckBarcodesWarningQueryResponse;
    readonly variables: DownloadOrdersDirectlyCheckBarcodesWarningQueryVariables;
};



/*
query DownloadOrdersDirectlyCheckBarcodesWarningQuery(
  $barcodes: [String!]!
) {
  checkIfGtinsExist(brand: "", gtins: $barcodes) {
    notFound
    found
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barcodes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "brand",
        "value": ""
      },
      {
        "kind": "Variable",
        "name": "gtins",
        "variableName": "barcodes"
      }
    ],
    "concreteType": "ProductGtins",
    "kind": "LinkedField",
    "name": "checkIfGtinsExist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notFound",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "found",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadOrdersDirectlyCheckBarcodesWarningQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DownloadOrdersDirectlyCheckBarcodesWarningQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25a2201902ba5a0659130ce4bc84f9a9",
    "id": null,
    "metadata": {},
    "name": "DownloadOrdersDirectlyCheckBarcodesWarningQuery",
    "operationKind": "query",
    "text": "query DownloadOrdersDirectlyCheckBarcodesWarningQuery(\n  $barcodes: [String!]!\n) {\n  checkIfGtinsExist(brand: \"\", gtins: $barcodes) {\n    notFound\n    found\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a9e0696ad5834502b9d17bf717fdd3d';
export default node;
