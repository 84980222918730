import React, {useState} from "react";
import {StoreType} from "./types";
import {Environment} from "relay-runtime";
import GrayModal from "../../ui-kit/src/dataDisplay/GrayModal";
import {PossibleError} from "../../ui-kit/src/commons/errors";
import {ourToast} from "../../ui-kit/src/atoms/Toast";
import EditRetailerStoreMutation from "../../ui-kit/src/mutations/EditRetailerStoreMutation";
import {StoreForm} from "./StoreForm";


type EditStoreModalProps =
  {
    isOpen: boolean,
    toggle: () => void,
    store: StoreType
    environment: Environment,
  }

type StoreDetailsType = {
  name: string,
  zipCode: string,
  address: string,
  city: string
}
export const isStoreNotChanged = (oldStore: StoreType, newStore: StoreDetailsType): boolean => {
  return oldStore.address === newStore.address &&
    oldStore.name === newStore.name &&
    oldStore.zipCode === newStore.zipCode &&
    oldStore.city === newStore.city
}
export default function EditStoreModal({isOpen, toggle, store, environment}: EditStoreModalProps) {
  let [storeDetails, setStoreDetails] = useState({
    name: store.name,
    zipCode: store.zipCode || "",
    address: store.address || "",
    city: store.city || ""
  })
  let [isLoading, setIsLoading] = useState(false)
  let [error, setError] = useState<string>("")

  const onSave = () => {
    EditRetailerStoreMutation(
      environment,
      {id: store.id, ...storeDetails},
      (data) => {
        ourToast("success", "Updated successfully");
        setIsLoading(false)
        toggle()
        setError("")
      },
      (error) => {
        ourToast("error", "Something went wrong", error[0].message);
        setError(error[0].message)
        setIsLoading(false)
      }
    )
  }

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{minWidth: '34rem'}}
    secondaryHeaderButton={{text: "Cancel", onClick: toggle}}
    primaryHeaderButton={{
      text: "Save",
      onClick: onSave,
      isDisabled: isStoreNotChanged(store, storeDetails) || isLoading || storeDetails.name.trim().length === 0
    }}
    title={store.name}
    bodyContent={<StoreForm
      storeDetails={storeDetails}
      isLoading={isLoading}
      error={error}
      setStoreDetails={setStoreDetails}
    />}
  />
}
