import React, {useState} from "react";
import GrayModal from "../../dataDisplay/GrayModal";
import {QueryRenderer, graphql} from 'react-relay'
import {DownloadOrdersModalContent} from "./components/DownloadOrdersModalContent";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {DownloadOrdersModalQuery} from "./__generated__/DownloadOrdersModalQuery.graphql";
import GenerateDdtOutputWithOrdersMutation from "../../mutations/output/GenerateDdtOutputWithOrdersMutation";
import {
  OrdersWithoutOrderConfirmationModalContent_orders$data
} from "./components/__generated__/OrdersWithoutOrderConfirmationModalContent_orders.graphql";
import {DdtType} from "../products/DistributeDataModalContentComponents/SelectDdtContent";

const query = graphql`
  query DownloadOrdersModalQuery {
    listDistributeDataTemplates {
      ...SelectDdtContent_ddts
    }
  }
`

export const steps = {
  SELECT: 0,
  LOADING: 1,
  ERROR: 2,
  FINISHED: 3,
  ORDERS_WITHOUT_ORDER_CONFIRMATION_WARNING: 4
}

export type DownloadOrderDataType = {
  id: string,
  orderNumbers: string[]
}[]

type DownloadOrdersModalProps = {
  isOpen: boolean,
  toggle: () => void,
  orders: DownloadOrderDataType,
  environment: RelayModernEnvironment
}

export default function DownloadOrdersModal({isOpen, toggle, orders, environment}: DownloadOrdersModalProps) {
  const [step, setStep] = useState(steps.SELECT);
  const [selectedDdt, setSelectedDdt] = useState<DdtType | null>(null);
  const [downloadImages, setDownloadImages] = useState(false);
  const [mergeSameProducts, setMergeSameProducts] = useState(true);
  const [outputProcessId, setOutputProcessId] = useState<string | null>(null);
  const [result, setResult] = useState<{id: string} | null>(null);
  const [generationError, setGenerationError] = useState<string | null>(null);
  const [ordersWithoutOrderConfirmationBatches, setOrdersWithoutOrderConfirmationBatches] = useState<OrdersWithoutOrderConfirmationModalContent_orders$data | null>(null);

  const onDistribute = (ddtValue: string) => {
    setGenerationError(null);
    setResult(null);
    GenerateDdtOutputWithOrdersMutation(
      environment,
      {
        orders: orders.map(order => order.id),
        ddt: ddtValue,
        withImages: downloadImages,
        mergeSameProducts: mergeSameProducts
      },
      (result) => {
        if (result.generateDdtOutputWithOrders?.outputProcess) {
          setOutputProcessId(result.generateDdtOutputWithOrders?.outputProcess.id || null);
          setStep(steps.LOADING)
        } else {
          setOrdersWithoutOrderConfirmationBatches(
            (result.generateDdtOutputWithOrders?.ordersWithoutOrderConfirmationBatches as unknown as OrdersWithoutOrderConfirmationModalContent_orders$data) || null
          );
          setStep(steps.ORDERS_WITHOUT_ORDER_CONFIRMATION_WARNING)
        }
      },
      (error) => {
        setStep(steps.ERROR);
        setGenerationError(error[0].message);
      }
    )
  }

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    title={(step !== steps.LOADING && step !== steps.FINISHED) ? 'Download orders' : undefined}
    style={{minWidth: '35rem'}}
    primaryHeaderButton={
      step === steps.SELECT ? {
        text: "Next",
        onClick: () => selectedDdt && onDistribute(selectedDdt.value),
        dataTestId: 'select-ddt-download-button',
        isDisabled: !selectedDdt
      } : undefined
    }
    secondaryHeaderButton={(step !== steps.LOADING && step !== steps.FINISHED) ? {text: 'Cancel', onClick: toggle} : undefined}
    bodyContent={
      <QueryRenderer<DownloadOrdersModalQuery>
        environment={environment}
        query={query}
        variables={{}}
        render={({error, props}) => {
          if (error) {
            return <p className={'text-danger'}>{error?.message} </p>
          } else if (props) {
            return <DownloadOrdersModalContent
              step={step}
              setStep={setStep}
              toggle={toggle}
              ddts={props.listDistributeDataTemplates}
              orders={orders}
              selectedDdt={selectedDdt}
              setSelectedDdt={setSelectedDdt}
              downloadImages={downloadImages}
              setDownloadImages={setDownloadImages}
              mergeSameProducts={mergeSameProducts}
              setMergeSameProducts={setMergeSameProducts}
              outputProcessId={outputProcessId}
              setOutputProcessId={setOutputProcessId}
              result={result}
              setResult={setResult}
              generationError={generationError}
              setGenerationError={setGenerationError}
              ordersWithoutOrderConfirmationBatches={ordersWithoutOrderConfirmationBatches}
              environment={environment}/>
          } else return <p>Loading...</p>
        }}
      />
    }
  />
}
