import React, {useEffect, useState} from 'react';
import LoginMutation from "../../../mutations/LoginMutation";
import {setAuthToken} from "../../../Environment";
import LoginModal, {loginFields} from "../../../ui-kit/src/specialized/userForms/login/LoginModal";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import {initializeAnonymousInUser} from "../../../common/intercomIntegration";
import {useHistory} from "react-router";
import {PayloadError} from "relay-runtime";
import {PayloadExtensions} from "relay-runtime/lib/network/RelayNetworkTypes";

function errorsBackendToFrontend(
  errors: readonly (PayloadError & { extensions?: PayloadExtensions })[] | Error,
  setBadFields: (val: string[]) => void
): (string | JSX.Element)[] | null {
  const errorMessages = [
    {backend: 'users.invalid_email', frontend: 'The email address is not valid.', badFields: [loginFields.email]},
    {backend: 'general.authentication_required', frontend: 'The email or password is incorrect.', badFields: [loginFields.email, loginFields.password]},
    {backend: 'App scope not allowed', frontend: <div>You are trying to log in to our app for retailers while you have a brand account. Please log in as a brand <a href={"https://brands.vuuh.com/login"}>here</a>.</div>}
  ]

  if(Array.isArray(errors) && errors.length) {
    const errorMessage = errorMessages.find(message => message.backend === errors[0].message || message.backend === errors[0]?.extensions?.params);
    if(errorMessage?.frontend) {
      setBadFields(errorMessage?.badFields || []);
      return [errorMessage.frontend];
    } else {
      setBadFields([]);
      return [errors[0].message];
    }
  } else {
    setBadFields([]);
    return [];
  }
}

function Login() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<(string | JSX.Element)[] | null>(null);
  const [badFields, setBadFields] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    initializeAnonymousInUser();
  }, [])

  const formSubmit = ({email, password}: {email: string, password: string}) => {
    let next = new URLSearchParams(window.location.search).get('next') || '/product/catalog'
    setLoading(true);
    LoginMutation(
      email, password,
      (response) => {
        setLoading(false);
        setErrors(null);
        setAuthToken(response.tokenAuth.token);
        history.push(next);
      }, (errors) => {
        setLoading(false);
        setErrors(errorsBackendToFrontend(errors, setBadFields));
      })
  };

  return (
    <div className="app flex-row align-items-center">
      <img src={bluredNavbar} className="blured-navbar" alt={'Blurred Vuuh Navbar'}/>
      <LoginModal onSubmit={formSubmit}
                  errors={errors}
                  loading={loading}
                  badFields={badFields}
      />
    </div>
  );

}

export default Login;
