import React, {useEffect, useState} from "react";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {graphql, QueryRenderer} from "react-relay";
import LoadingModalContent from "../../../molecules/loading/LoadingModalContent";
import loadingStyles from "../../../molecules/loading/LoadingModalContent.module.scss";
import {
  DownloadOrdersDirectlyCheckBarcodesWarningQuery
} from "./__generated__/DownloadOrdersDirectlyCheckBarcodesWarningQuery.graphql";
import Pill from "../../../atoms/Pill";
import styles from "./DownloadOrdersDirectlyCheckBarcodesWarning.module.scss";
import yellowExclamationMarkIcon from "../../../assets/svg/yellowExclamationMarkIcon.svg";
import {pluralizeByCount} from "../../../stringUtils";
import {ourToast} from "../../../atoms/Toast";

const query = graphql`
  query DownloadOrdersDirectlyCheckBarcodesWarningQuery($barcodes: [String!]!) {
    checkIfGtinsExist(gtins: $barcodes) {
      notFound
      found
    }
  }
`

type DownloadOrdersDirectlyCheckBarcodesWarningContentProps = {
  missingBarcodes: string[],
  isRetailer: boolean,
  onDownload: () => void,
  toggle: () => void,
}

function DownloadOrdersDirectlyCheckBarcodesWarningContent({
                                                             missingBarcodes,
                                                             isRetailer,
                                                             onDownload,
                                                             toggle
                                                           }: DownloadOrdersDirectlyCheckBarcodesWarningContentProps) {
  const [showAll, setShowAll] = useState(false);
  const visibleBarcodes = showAll ? missingBarcodes : missingBarcodes.slice(0, 3);
  const infoText = isRetailer ? <>
    Send the list of barcodes below to the brand, ask them to add the<br/>
    related product data, and then try adding the order again.
  </> : <>
    To add barcodes to Vuuh, add the related product data, and<br/>
    then try downloading the order again.
  </>

  const onCopyBarcodesToClipboard = () => {
    navigator.clipboard.writeText(missingBarcodes.join('\n')).then(() => {
      ourToast("success", "Copied!");
    });
  }

  useEffect(() => {
    if (missingBarcodes.length === 0) {
      onDownload();
    }
  }, [missingBarcodes]);

  return <div className={styles.container}>
    <div className={styles.closeIconContainer} onClick={toggle}>
      <i className={"fa-light fa-xmark"}/>
    </div>
    <img src={yellowExclamationMarkIcon} alt={"!"}/>
    <h2 className={styles.title}>{missingBarcodes.length} {pluralizeByCount("barcode", missingBarcodes.length)} not on
      our system</h2>
    <p className={`mt-2 mb-3 ${styles.infoText}`}>{infoText}</p>
    <Pill onClick={onDownload}
          icon={"fa-light fa-arrow-down-to-line"}
          className={styles.downloadAnywayPill}>
      Download anyway
    </Pill>
    <hr className={styles.separator}/>
    <p className={styles.infoText}>
      Barcodes not on Vuuh -&nbsp;
      <label onClick={onCopyBarcodesToClipboard}
             className={styles.copyBarcodesText}>
        copy the list
      </label>
    </p>
    {visibleBarcodes.length < 3 ? (
      <div className={styles.missingBarcodesSmallContainer}>
        {visibleBarcodes.map(barcode => (
          <p key={barcode}
             className={styles.barcodeText}>
            {barcode}
          </p>
        ))}
      </div>
    ) : (
      <div className={styles.missingBarcodesContainer}>
        {visibleBarcodes.map(barcode => (
          <p key={barcode}
             className={styles.barcodeText}>
            {barcode}
          </p>
        ))}
      </div>
    )}
    {missingBarcodes.length > 3 && <div onClick={() => setShowAll(!showAll)}
                                        className={styles.showAllButton}>
      {showAll && <i className={"fa-regular fa-angle-up"}/>}
      <p className={'m-0'}>Show {!showAll ? "all" : "less"}</p>
      {!showAll && <i className={"fa-regular fa-angle-down"}/>}
    </div>}
  </div>
}

type DownloadOrdersDirectlyCheckBarcodesWarningProps = {
  barcodesList: string[],
  isRetailer: boolean,
  onDownload: () => void,
  onError: (err: string) => void,
  environment: RelayModernEnvironment,
  toggle: () => void,
}

export default function DownloadOrdersDirectlyCheckBarcodesWarning({
                                                                     barcodesList,
                                                                     isRetailer,
                                                                     onDownload,
                                                                     onError,
                                                                     environment,
                                                                     toggle
                                                                   }: DownloadOrdersDirectlyCheckBarcodesWarningProps) {

  return <QueryRenderer<DownloadOrdersDirectlyCheckBarcodesWarningQuery>
    environment={environment}
    query={query}
    variables={{
      barcodes: barcodesList,
    }}
    render={({props, error}) => {
      if (error) {
        onError(error?.message || "Failed to check for missing barcodes.");
      }

      if (props) {
        return <DownloadOrdersDirectlyCheckBarcodesWarningContent
          isRetailer={isRetailer}
          missingBarcodes={props.checkIfGtinsExist?.notFound as string[] || []}
          onDownload={onDownload}
          toggle={toggle}/>
      }

      return <LoadingModalContent
        subtitle={"A file is being prepared for you to download."}
        actionButton={
          <Pill onClick={toggle} className={`mt-3 ${loadingStyles.button}`}>
            <p className={`m-0 ${loadingStyles.buttonText}`}>Cancel</p>
          </Pill>
        }/>
    }}
  />
}
