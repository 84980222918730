import React from 'react';
import styles from './DownloadOrdersDirectlyDownload.module.scss'
import {QueryRenderer, graphql} from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  DownloadOrdersDirectlyDownloadQuery,
} from "./__generated__/DownloadOrdersDirectlyDownloadQuery.graphql";
import {downloadFile} from "../../../commons/http";
import greenCircleCheck from "../../../assets/svg/greenCircleCheck.svg";
import Pill from "../../../atoms/Pill";
import {ourToast} from "../../../atoms/Toast";
import LoadingModalContent from "../../../molecules/loading/LoadingModalContent";
import loadingStyles from "../../../molecules/loading/LoadingModalContent.module.scss";

const query = graphql`
  query DownloadOrdersDirectlyDownloadQuery($fileId: ID!) {
    getDistributeDataOutput(id: $fileId) {
      id
      url
      distributeDataTemplate {
        id
        name
        engine
        outputType
      }
      productsSuccessCount
      productsFailedCount
    }
  }
`;

type DownloadOrdersDirectlyDownloadProps = {
  resultId: string,
  onError: (val: string) => void,
  toggle: () => void,
  environment: RelayModernEnvironment
}

function DownloadOrdersDirectlyDownload({resultId, onError, toggle, environment}: DownloadOrdersDirectlyDownloadProps) {

  return <div>
    <QueryRenderer<DownloadOrdersDirectlyDownloadQuery>
      environment={environment}
      query={query}
      variables={{fileId: resultId}}
      render={({error, props}) => {
        if (error) {
          onError(error.message)
        }

        if (props) {
          if (props?.getDistributeDataOutput?.url) {
            return <div className={styles.container}>
              <div>
                <i className={`fa-light fa-xmark ${styles.toggleButton}`} onClick={toggle}/>
              </div>
              <img src={greenCircleCheck} alt={"greenCircleCheck"}/>
              <h1 className={styles.title}>Your file is ready</h1>
              <p className={styles.subtitle}>
                You can now download the file we prepared for you.
              </p>
              <Pill icon={"fa-light fa-arrow-down"}
                    className={styles.downloadButton}
                    onClick={() => {
                      if (props.getDistributeDataOutput?.url) {
                        downloadFile(props.getDistributeDataOutput.url)
                      } else {
                        ourToast("error", "No download url found!");
                      }
                    }}>
                Download
              </Pill>
            </div>
          }

          onError("No download URL found");
        }

        return <LoadingModalContent
          subtitle={"A file is being prepared for you to download."}
          actionButton={
            <Pill onClick={toggle} className={`mt-3 ${loadingStyles.button}`}>
              <p className={`m-0 ${loadingStyles.buttonText}`}>Cancel</p>
            </Pill>
          }/>
      }}
    />
  </div>
}

export default DownloadOrdersDirectlyDownload
