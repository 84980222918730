import React, {useState} from 'react';
import GrayModal from "../../../dataDisplay/GrayModal";
import {ErrorAlert} from "../../../commons/errors";
import OrderViaBarcodesInfo from "./OrderViaBarcodesInfo";
import {
  OrderViaBarcodesModalProps,
  OrderInfoType,
  ModalDataType,
  VariantType,
} from "./types";
import OrderViaBarcodesAddInfo from "./OrderViaBarcodesAddInfo";
import OrderViaBarcodesNoConnectionsWarning from "./OrderViaBarcodesNoConnectionsWarning";
import OrderViaBarcodesAddBarcodes from "./OrderViaBarcodesAddBarcodes";
import OrderViaBarcodesMissingBarcodesWarningRenderer from "./OrderViaBarcodesMissingBarcodesWarning";
import OrderViaBarcodesQuantitiesAndPrices from "./addQuantitiesAndPricesStep/OrderViaBarcodesQuantitiesAndPrices";
import OrderViaBarcodesCreateOrder from "./OrderViaBarcodesCreateOrder";

const STEPS = {
  INFO: 0,
  ADD_ORDER_INFO: 1,
  NO_CONNECTIONS_WARNING: 2,
  ADD_BARCODES: 3,
  MISSING_BARCODES_WARNING: 4,
  ADD_QUANTITIES_AND_PRICES: 5,
  CREATE_ORDER: 6
}

const removeEmptyChars = (val: string) => {
  return val.replaceAll(" ", "").replaceAll("\n", "");
}

export default function OrderViaBarcodesModal({isOpen, toggle, isRetailer, scopeUser, environment}: OrderViaBarcodesModalProps) {
  const [step, setStep] = useState(STEPS.INFO);
  const [orderInfo, setOrderInfo] = useState<OrderInfoType>({
    brandOrRetailer: undefined,
    orderNumber: undefined,
    retailerStore: undefined,
    currency: undefined
  })
  const [barcodes, setBarcodes] = useState("");
  const [existingBarcodes, setExistingBarcodes] = useState<string[]>([])
  const [variants, setVariants] = useState<VariantType[]>([])
  const orderBrand = isRetailer ? orderInfo.brandOrRetailer : scopeUser;
  const orderRetailer = isRetailer ? scopeUser : orderInfo.brandOrRetailer;

  const getModalData = () => {
    const defaultModalData: ModalDataType = {
      title: "Add an order via barcodes",
      secondaryHeaderButton: {
        text: "Cancel",
        onClick: toggle,
      },
      bodyContent: <ErrorAlert error={"Invalid step"}/>
    };

    const steps = [
      {stepText: "Add order info"},
      {stepText: "Add barcodes"},
      {stepText: "Add quantities and prices"}
    ]

    if (step === STEPS.INFO) {
      return {
        ...defaultModalData,
        primaryHeaderButton: {
          text: "Continue",
          onClick: () => setStep(STEPS.ADD_ORDER_INFO)
        },
        bodyContent: <OrderViaBarcodesInfo />
      }
    } else if (step === STEPS.ADD_ORDER_INFO) {
      return {
        ...defaultModalData,
        title: "Adding an order via barcodes",
        primaryHeaderButton: {
          text: "Next",
          onClick: () => setStep(STEPS.ADD_BARCODES),
          isDisabled: !Object.entries(orderInfo).every(([, val]) => val)
        },
        steps: steps,
        stepIndex: 0,
        bodyContent: <OrderViaBarcodesAddInfo
          isRetailer={isRetailer}
          scopeId={scopeUser!.id}
          noBrandsOrRetailersHandler={() => setStep(STEPS.NO_CONNECTIONS_WARNING)}
          environment={environment}
          {...orderInfo}
          setBrandOrRetailer={(newBrandOrRetailer: OrderInfoType["brandOrRetailer"]) => setOrderInfo({
            ...orderInfo,
            brandOrRetailer: newBrandOrRetailer
          })}
          setOrderNumber={(newOrderNumber: OrderInfoType["orderNumber"]) => setOrderInfo({
            ...orderInfo,
            orderNumber: newOrderNumber
          })}
          setRetailerStore={(newRetailerStore: OrderInfoType["retailerStore"]) => setOrderInfo({
            ...orderInfo,
            retailerStore: newRetailerStore
          })}
          setCurrency={(newCurrency: OrderInfoType["currency"]) => setOrderInfo({
            ...orderInfo,
            currency: newCurrency
          })}
        />
      }
    } else if (step === STEPS.NO_CONNECTIONS_WARNING) {
      return {
        ...defaultModalData,
        secondaryHeaderButton: undefined,
        title: undefined,
        bodyContent: <OrderViaBarcodesNoConnectionsWarning toggle={toggle}
                                                           isRetailer={isRetailer}/>
      }
    } else if (step === STEPS.ADD_BARCODES) {
      return {
        ...defaultModalData,
        title: "Adding an order via barcodes",
        primaryHeaderButton: {
          text: "Next",
          onClick: () => setStep(STEPS.MISSING_BARCODES_WARNING),
          isDisabled: !removeEmptyChars(barcodes)
        },
        steps: steps,
        stepIndex: 1,
        bodyContent: <OrderViaBarcodesAddBarcodes barcodes={barcodes}
                                                  setBarcodes={setBarcodes}/>
      }
    } else if (step === STEPS.MISSING_BARCODES_WARNING) {
      const barcodesList = barcodes
        .split("\n")
        .map(b => b.replaceAll(" ", ""))
        .filter(b => !!b)

      return {
        bodyContent: <OrderViaBarcodesMissingBarcodesWarningRenderer
          isBrand={!isRetailer}
          brandId={orderBrand?.id || ""}
          barcodes={barcodesList}
          setExistingBarcodes={setExistingBarcodes}
          toAddQuantitiesAndPrices={() => setStep(STEPS.ADD_QUANTITIES_AND_PRICES)}
          toggle={toggle}
          onBack={() => setStep(STEPS.ADD_BARCODES)}
          environment={environment}
        />
      }
    } else if (step === STEPS.ADD_QUANTITIES_AND_PRICES) {
      return {
        ...defaultModalData,
        title: "Adding an order via barcodes",
        primaryHeaderButton: {
          text: "Add order",
          onClick: () => setStep(STEPS.CREATE_ORDER),
          isDisabled: !variants.length || !variants.every(v => v.subvariants.every(s => {
            return s.quantity != null && s.discount != null && s.finalPrice != null && s.totalPrice != null
          }))
        },
        steps: steps,
        stepIndex: 2,
        bodyContent: <OrderViaBarcodesQuantitiesAndPrices existingBarcodes={existingBarcodes}
                                                          currency={orderInfo.currency}
                                                          variants={variants}
                                                          setVariants={setVariants}
                                                          environment={environment}/>
      }
    } else if (step === STEPS.CREATE_ORDER) {
      return {
        bodyContent: <OrderViaBarcodesCreateOrder orderBrand={orderBrand}
                                                  orderRetailer={orderRetailer}
                                                  orderInfo={orderInfo}
                                                  variants={variants}
                                                  toggle={toggle}/>
      }
    }

    return defaultModalData;
  }

  const modalData = getModalData();

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{ minWidth: "fit-content" }}
    title={modalData.title}
    primaryHeaderButton={modalData.primaryHeaderButton}
    secondaryHeaderButton={modalData.secondaryHeaderButton}
    steps={modalData.steps}
    stepsIndex={modalData.stepIndex}
    bodyContent={modalData.bodyContent}
  />
}
