import EnableSendEmailWithOutputOnCompletionMutation from "../../../mutations/EnableSendEmailWithOutputOnCompletionMutation";
import {ourToast} from "../../../atoms/Toast";
import React from "react";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Pill from "../../../atoms/Pill";
import LoadingModalContent from "../../../molecules/loading/LoadingModalContent";

type DownloadOrdersDirectlyLoadingProps = {
  toggle: () => void,
  hasDownloadImages: boolean,
  environment: RelayModernEnvironment,
  outputProcessId: string | null,
  outputType?: string
}

function DownloadOrdersDirectlyLoading({toggle, hasDownloadImages, outputType, environment, outputProcessId}: DownloadOrdersDirectlyLoadingProps) {
  const infoText = hasDownloadImages ? 'This may take a while due to the processing of images.' : 'A file is being prepared for you to download.';
  const isNotifyViaEmailAvailable = outputType === "TEMPLATE" && outputProcessId;

  return <LoadingModalContent
    subtitle={infoText}
    actionButton={
      <>
        {isNotifyViaEmailAvailable && <Pill
          className={'mt-3'}
          onClick={() => {
            EnableSendEmailWithOutputOnCompletionMutation(
              environment,
              {ddtOutputProcessId: outputProcessId},
              (result) => {
                if (result.enableSendEmailWithOutputOnCompletion?.success) {
                  ourToast("success", "An email will be sent when ready")
                  toggle();
                } else {
                  ourToast("error", "Something went wrong");
                }
              },
              (err) => {
                ourToast("error", "Something went wrong", err[0].message);
              }
            )
          }}
          data-testid={"orders-button-download-content-directly-email-notify"}
        >
          Get notified via email when ready
        </Pill>}
        <Pill onClick={toggle}
              style={{marginTop: isNotifyViaEmailAvailable ? "0.75rem" : "1rem"}}>
          Cancel
        </Pill>
      </>
    }
  />
}

export default DownloadOrdersDirectlyLoading
