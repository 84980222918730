import React, {useState} from "react";
import Modal from "reactstrap/lib/Modal";
import {QueryRenderer, graphql} from 'react-relay';
import Alert from "reactstrap/lib/Alert";
import ArcModal from "../../../../dataDisplay/ArcModal";
import ModalContent from "./ModalContent"
import {useWindowDimensions} from "../../../../commons/hooks";
import {CurrentUser} from "../../../../context/CurrentUserContext";
import {V2DDTConfigModalQuery, V2DDTConfigModalQueryResponse} from "./__generated__/V2DDTConfigModalQuery.graphql";
import Loading from "../../../../atoms/Loading";
import SheetSelectionModalContent from "./sheetSelection/SheetSelectionModalContent";
import {ProductTemplateInfoType, SheetType} from "./sheetSelection/types";
import {SheetSelectionProvider} from "./sheetSelection/SheetSelectionContext";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {ourToast} from "../../../../atoms/Toast";
import UpdateMultipleSheetsConfigurationMutation from "../../../../mutations/UpdateMultipleSheetsConfigurationMutation";
import {DDTMappingConfig} from "../../../../mutations/__generated__/UpdateMultipleSheetsConfigurationMutation.graphql";
import {MeasurementDataType} from "../../DDTMappingConfigV2Form/types";

const query = graphql`
  query V2DDTConfigModalQuery($ddtId: ID!)
  {
    listProductTemplates {
      ...ModalContent_productTemplates @arguments(ddt: $ddtId, configureImageNaming: false)
      edges {
        node {
          id
          name
          fields: schemaFields {
            name
            path
            type
          }
        }
      }
    }
    getDdt(id: $ddtId) {
      ...ModalContent_ddt @arguments(configureImageNaming: false)
      name
      distributedatamappingconfigSet {
        edges {
          node {
            id
            useSystem
            isActive
            sheetName
            sheetProductSelectors
            config
            configBeforeVariant
            skipFirstVariant
            productTemplate {
              id
            }
          }
        }
      }
    },
    listMeasurements(kind: "All"){
      name
      category
    },
    ...ModalContent_exampleProducts,
  }
`

function MisconfiguredNoProduct({onClose}: { onClose: () => void }) {
  return <ArcModal title={'Output template is mis-configured'}
                   isOpen={true}
                   secondaryActionText={'Close'}
                   onSecondaryAction={onClose} toggle={undefined} loading={undefined}
                   icon={undefined} primaryActionText={undefined} onPrimaryAction={undefined} linkActionText={undefined}
                   onLinkAction={undefined}>
    No product type is configured to use this output template.
    Please contact an administrator to fix this issue
  </ArcModal>
}

type V2DDTConfigModalProps = {
  isOpen: boolean,
  toggle: () => void,
  ddtId: string,
  environment: RelayModernEnvironment,
  user: CurrentUser,
  brands: {
    name: string,
    id: string
  }[],
  isRetailer: boolean,
  disableBackdropToggle?: boolean
}


type SimplifiedDdtShapeType = {
  readonly distributedatamappingconfigSet: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly useSystem: string,
        readonly productTemplate: {
          readonly id: string
        } | null
      } | null
    } | null>
  }
} | null

function shouldShowMultipleSheetsFlow(ddt: SimplifiedDdtShapeType) {
  if (!ddt) {
    return false;
  }
  let mappingConfigCount = ddt.distributedatamappingconfigSet.edges.length;
  if (mappingConfigCount === 1 || mappingConfigCount === 0) {
    return false;
  }
  if (mappingConfigCount > 1) {
    return true;
  }
  // maybe other cases I don't see, fallback to "classic" one sheet flow directly.
  return false;
}

function isMisconfigured(ddt: SimplifiedDdtShapeType) {
  if (!ddt) {
    // no ddt? ok something is really wrong then...
    return true;
  }
  if (ddt.distributedatamappingconfigSet.edges.length === 0) {
    // no mapping configs
    return true;
  }
  if (ddt.distributedatamappingconfigSet.edges.filter(edge => !edge?.node?.productTemplate).length) {
    // at least one mapping config without a product template.
    return true;
  }
  if (ddt.distributedatamappingconfigSet.edges.filter(edge => {
    return edge?.node?.useSystem !== 'V2_CONFIG';
  }).length) {
    // at least one non-"v2 config" mapping, either null or old jmespath style
    return true;
  }
  return false;
}


function gatherProductTemplates(productTemplates: V2DDTConfigModalQueryResponse['listProductTemplates']): ProductTemplateInfoType[] {
  if (!productTemplates?.edges.length) {
    return []
  }
  return productTemplates.edges.map(edge => {
    if (!edge?.node) {
      return null;
    }
    return {
      id: edge.node.id,
      fields: edge.node.fields?.map(
          x => {
            if (!x || !x.type) {
              return null;
            }
            return {
              path: x.path,
              name: x.name,
              type: x.type[0]
            }
          }
        ).filter(x => x) as { path: string, name: string, type: string }[] || []
    }
  }).filter(x => x) as ProductTemplateInfoType[]
}

function adaptSheetsToMutationInputs(sheets: SheetType[]): Array<DDTMappingConfig> {
  return sheets.map(sh => {
    return {
      id: sh.mappingConfigId,
      isActive: sh.isActive,
      config: sh.config,
      configBeforeVariant: sh.configBeforeVariant,
      skipFirstVariant: sh.skipFirstVariant || false,
      productSelectors: sh.productSelectors,
    }
  })
}

function getAllMeasurements(listMeasurements: V2DDTConfigModalQueryResponse['listMeasurements']): MeasurementDataType[] {
    return listMeasurements
      .filter(m => m?.name != null && m?.category != null)
      .map(m => ({
      name: m!.name,
      category: m!.category
    }))
}

function V2DDTConfigModal({
                            isOpen,
                            toggle,
                            ddtId,
                            environment,
                            user,
                            brands,
                            isRetailer,
                            disableBackdropToggle = true
                          }: V2DDTConfigModalProps) {
  const modalWidth = useWindowDimensions().width * 0.7;
  const [selectedSheetName, setSelectedSheetName] = useState<string | null>(null);


  function persistMappingConfigStatesWithSheets(sheets: SheetType[]) {
    UpdateMultipleSheetsConfigurationMutation(
      environment as RelayModernEnvironment,
      {
        configs: adaptSheetsToMutationInputs(sheets),
      },
      resp => {
        ourToast('success', 'Saved');
      },
      err => {
        ourToast('error', "Something went wrong", err[0].message)
      }
    )
  }

  return <SheetSelectionProvider initialData={[]} productTemplates={[]}>
    <Modal isOpen={isOpen}
           backdrop={disableBackdropToggle ? 'static' : true}
           toggle={toggle}
           style={{
             minWidth: '950px',
             width: `${modalWidth}px`,
             maxWidth: '1500px',
           }}
           contentClassName={"ddt-config-modal"}>
      <QueryRenderer<V2DDTConfigModalQuery>
        environment={environment}
        query={query}
        variables={{ddtId: ddtId}}
        render={({props, error}) => {
          if (error) {
            return <Alert color={'danger'}>Failed to load the output template. Please try again.</Alert>
          } else if (props) {
            if (isMisconfigured(props.getDdt)) {
              return <MisconfiguredNoProduct onClose={toggle}/>
            }

            if (!selectedSheetName && shouldShowMultipleSheetsFlow(props.getDdt)) {
              const sheetObjects = props.getDdt?.distributedatamappingconfigSet.edges.map(edge => {
                if (!edge?.node) {
                  return null;
                }
                return {
                  mappingConfigId: edge.node.id,
                  name: edge.node.sheetName,
                  isActive: edge.node.isActive,
                  productSelectors: edge.node.sheetProductSelectors ? JSON.parse(edge.node.sheetProductSelectors as string) : [],
                  productTemplateId: edge.node.productTemplate?.id,
                  config: (edge.node.config || {}) as object,
                  configBeforeVariant: (edge.node.configBeforeVariant || {}) as object,
                  skipFirstVariant: edge.node.skipFirstVariant || false
                }
              }).filter(x => x) as SheetType[]

              return <SheetSelectionModalContent
                sheets={sheetObjects}
                productTemplates={gatherProductTemplates(props.listProductTemplates)}
                environment={environment}
                outputName={props.getDdt?.name || 'unknown'}
                onClose={() => {
                  setSelectedSheetName(null);
                  toggle()
                }}
                onSave={(sheets) => persistMappingConfigStatesWithSheets(sheets)}
                selectedSheetName={selectedSheetName}
                setSelectedSheetName={setSelectedSheetName}
              />
            }
            return <ModalContent
              key={selectedSheetName}  // force a full rebuild + state reinitialization on active sheet name change
              ddt={props.getDdt}
              selectedSheetName={selectedSheetName}
              productTemplates={props.listProductTemplates}
              setSelectedSheetName={setSelectedSheetName}
              onCancel={() => {
                setSelectedSheetName(null);
                toggle()
              }}
              onSave={(sheets: SheetType[]) => persistMappingConfigStatesWithSheets(sheets)}
              exampleProducts={props}
              user={user}
              isRetailer={isRetailer}
              brands={brands}
              allMeasurements={getAllMeasurements(props.listMeasurements)}
            />
          }
          return <Loading className={'mx-auto'}/>
        }}
      />
    </Modal>
  </SheetSelectionProvider>
}

export default V2DDTConfigModal;
