import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  GenerateDdtOutputWithOrdersInput,
  GenerateDdtOutputWithOrdersMutationResponse
} from "./__generated__/GenerateDdtOutputWithOrdersMutation.graphql";

const mutation = graphql`
  mutation GenerateDdtOutputWithOrdersMutation($input: GenerateDdtOutputWithOrdersInput!) {
    generateDdtOutputWithOrders (input: $input) {
      outputProcess {
        id
        state
      }
      ordersWithoutOrderConfirmationBatches {
        ...OrdersWithoutOrderConfirmationModalContent_orders
      }
    }
  }
`;

function GenerateDdtOutputWithOrdersMutation(
  environment: RelayModernEnvironment,
  data: GenerateDdtOutputWithOrdersInput,
  onSuccess: (val: GenerateDdtOutputWithOrdersMutationResponse) => void,
  onError: (val: Error[]) => void
) {
  createMutationBoilerplate(mutation)(environment, data, onSuccess as (val: unknown) => void, onError)
}

export default GenerateDdtOutputWithOrdersMutation;
