import {ModalErrorAlert} from "../../ui-kit/src/commons/errors";
import {Col, Row} from "reactstrap";
import Input from "../../ui-kit/src/atoms/Input"
import styles from "./StoreForm.module.scss";
import React from "react";
import translateErrorMessage from "./helpers";

type StoreFormProps = {
  storeDetails: { name: string, zipCode: string, address: string, city: string }
  setStoreDetails: (newStore: { name: string, zipCode: string, address: string, city: string }) => void
  isLoading: boolean
  error?: string
}

export function StoreForm({storeDetails, setStoreDetails, isLoading, error}: StoreFormProps) {

  return <Col className={"px-0 py-3"} data-testid={'store-form'}
  >
    <Row className={"mx-5 py-2"}>
      <Col style={{maxWidth: "6.25rem"}} className={"d-flex justify-content-end"}>
        <span className={"ml-auto text-right"}>Name</span>
      </Col>
      <Col>
        <Input value={storeDetails.name}
               placeholder={"Type in location name"}
               disabled={isLoading}
               className={styles.standardInput}
               onChange={(e) => setStoreDetails({...storeDetails, name: e.target.value})}/>
      </Col>
    </Row>
    <Row className={"mx-5"}>
      <hr className={"w-100 " + styles.grayElement}/>
    </Row>
    <Row className={"mx-5 py-2"}>
      <Col style={{maxWidth: "6.25rem"}} className={"d-flex justify-content-end"}>
        <span className={"ml-auto text-right"}>Address</span>
      </Col>
      <Col>
        <Input value={storeDetails.address}
               disabled={isLoading}
               className={styles.standardInput}
               onChange={(e) => setStoreDetails({...storeDetails, address: e.target.value})}/>
      </Col>
    </Row>
    <Row className={"mx-5 py-2"}>
      <Col style={{maxWidth: "6.25rem"}} className={"d-flex justify-content-end"}>
        <span className={"ml-auto text-right"}>Zip Code</span>
      </Col>
      <Col>
        <Input value={storeDetails.zipCode}
               disabled={isLoading}
               className={styles.zipCodeInput}
               onChange={(e) => setStoreDetails({...storeDetails, zipCode: e.target.value})}/>
      </Col>
    </Row>
    <Row className={"mx-5 py-2"}>
      <Col style={{maxWidth: "6.25rem"}} className={"d-flex justify-content-end"}>
        <span className={"ml-auto text-right"}>City</span>
      </Col>
      <Col>
        <Input value={storeDetails.city}
               disabled={isLoading}
               className={styles.standardInput}
               onChange={(e) => setStoreDetails({...storeDetails, city: e.target.value})}/>
      </Col>
    </Row>
    {error && <Row className={"m-5"}>
      <ModalErrorAlert error={error} translator={translateErrorMessage}/>
    </Row>}
  </Col>
}